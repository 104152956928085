.normal-drawer .ant-drawer-body {
    flex: 1;
    min-width: 0;
    min-height: 0;
    padding: 24px;
    overflow: auto;
    font-family: 'NLRTM-Regular', 'Trebuchet MS', 'Arial', sans-serif !important;
}

.special-drawer .ant-drawer-body {
    flex: 1;
    min-width: 0;
    min-height: 0;
    padding: 0px;
    overflow: auto;
    font-family: 'NLRTM-Regular', 'Trebuchet MS', 'Arial', sans-serif !important;
}

.ant-drawer .ant-drawer-content {
    background: #001f4b;
}

.filterDrawer {
    background-color: #001f4b;
    margin-top: 50px;
    height: auto;
    color: #fff;
    padding: 0px 0 15px;
}

.tableFilterLable {
    font-family: 'NLRTM-Bold', 'Trebuchet MS', 'Arial', sans-serif !important;
    font-size: 20px !important;
    text-align: start;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.flexdiv {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 20px;
}

.fieldLable {
    font-family: NLRTM-Bold, Trebuchet MS, Arial, sans-serif !important;
    font-size: 16px !important;
    text-align: start;
    padding-bottom: 10px;
}

.buttonFlexDiv {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 15px;
}

.extraFieldLable {
    font-family: NLRTM-Bold, Trebuchet MS, Arial, sans-serif;
    font-size: 20px;
    text-align: start;
    padding-bottom: 10px;
}

.datepickerStyle {
    background-color: #ffff;
    color: rgb(0, 31, 75);
    padding: 10px 10px 0px 10px !important;
}

.textRed {
    color: red
}
.spanTextStyle {
    font-family: NLRTM-Bold, Trebuchet MS, Arial, sans-serif;
    font-size: 15px;
    text-align: start;
    padding-bottom: 10px;
    color: red;
}

.dropdownStyleFlex {
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 30px;
    display: grid;
    margin: 15px 0px 0;
}

.dropdownStyle {
    background-color: #ffff !important;
    color: rgb(0, 31, 75);
    padding: 10px 10px 10px !important;
}

.maximumHeight { min-height: 50px !important }

.buttonApply {
    background: #009cd6 !important;
    border-radius: 3px;
    color: #fff !important;
    display: inline-block;
    font-family: NLRTM-Bold, Trebuchet MS, Arial, sans-serif;
    font-size: 16px;
    justify-self: stretch;
    line-height: 1;
    padding: 0rem 1rem;
    text-align: center;
    border: none !important;
    transition: background-color .3s, border-color .3s, color .3s;
    width: 100%;
}

