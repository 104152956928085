.errorBlock {
    align-items: center;
    background-color: #F3F4F6;
}

.errorFlex {
    display: flex;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #374151;
}

.maxWidthBlock-28rem {
    max-width: 28rem;
}

.maxWidthBlock-32rem {
    max-width: 32rem;
}

.errorStatusCode {
    font-size: 3rem;
    line-height: 1;
    font-weight: 700
}

.errorStatusStyle {
    font-size: 1.5rem;
    line-height: 2rem;
    line-height: 1.5;
    font-weight: 300
}

.marginBottom-1rem {
    margin-bottom: 1rem
}

.marginBottom-2rem {
    margin-bottom: 2rem
}

.fontWeightBold {
    font-weight: bold
}

.backStyle {
    display: inline;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.5rem;
    border-width: 1px;
    border-color: transparent;
    font-size: 0.875rem;
    line-height: 1.25rem;
    line-height: 1.25rem;
    font-weight: 500;
    color: #ffffff;
    background-color: #2563EB;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    transition-duration: 150ms;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

    :hover {
        background-color: #1D4ED8
    }
}

.flexFooter {
    display: 'flex'
}