.ant-drawer-right .ant-drawer-content-wrapper {
  bottom: auto !important;
  /* Override the default style */
}

.ant-table-wrapper .ant-table-thead>tr>th {
  background: #011129;
  color: #fff;
  border-bottom: 1px solid #536785;
}

.ant-table-wrapper .ant-table-thead-hover>tr>th {
  background: #011129 !important;
  color: #fff;
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
  background: #011129 !important;
  color: #fff;
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #011129 !important;
  color: #fff;
}

.ant-table-row {
  background: #001f4b;
  color: #fff;
}

.ant-table-cell-row-hover {
  background: #001f4b !important;
  color: #fff;
}

.ant-table-wrapper td.ant-table-column-sort {
  background: #001f4b !important;
  color: #fff;
}

.ant-table-wrapper .ant-table-column-sorter {
  color: #0087c6;
}

.ant-table-wrapper .ant-table-filter-trigger {
  color: #fff;
}

.ant-table-body {
  min-height: 83.8vh !important;
  background: #001f4b;
}

.anticon {
  color: #fff;
}

.ant-layout * {
  border-radius: 0px !important;
}

.ant-table-wrapper .ant-table-tbody>tr>td {
  border-bottom: 1px solid #536785
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected {
  color: #fff;
  background-color: #429ad1;
  border-radius: 0px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  color: #fff;
}

.ant-dropdown .ant-dropdown-menu {
  border-radius: 0px
}

.custom-button:hover {
  background-color: rgb(0, 21, 52);
  color: #fff;
}

.custom-button {
  background-color: #f9d949;
  color: #001f4b;
}

.ant-empty-normal {
  min-height: 82vh;
}

.ant-table-wrapper .ant-table-expanded-row-fixed {
  background-color: #001f4b;
}

.ant-empty-normal .ant-empty-description {
  color: #fff;
}

.normal-drawer .ant-drawer-body {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding: 24px;
  overflow: auto;
}

.special-drawer .ant-drawer-body {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding: 0px;
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.minHeight {
  min-height: 80vh;
}