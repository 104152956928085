.headerStyle {
    align-items: center;
    background-color: #001f4b;
    border-bottom: 1px solid #536785;
    display: flex;
    flex-shrink: 0;
    height: 50px !important;
    max-height: 50px;
    position: relative;
    width: 100%;
    padding: 0
}

div.highlight span {
    color: yellow;
    font-size: medium;
}

.headerFlex {
    align-items: center;
    display: flex;
    height: 100%;
}

.headerButton {
    align-items: center;
    appearance: none;
    border-right: 1px solid #536785;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    min-width: 50px;
    outline: none;
    user-select: none;
    color: #ffffff;
}

.headerButtonAnchor {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 0 10px
}

.h4 {
    align-items: center;
    color: #ffffff;
    display: flex;
    font-family: 'NLRTM-Bold', 'Trebuchet MS', 'Arial', sans-serif;
    font-size: 14px;
    justify-content: space-between;
    margin-left: 6px;
    margin-top: 23px;
}

.marginLeft-auto {
    margin-left: auto;
}

.borderLeft {
    border-left: 1px solid #536785;
}

.menu {}

.menu:hover {
    background-color: #00124b;
    border-bottom: 1px solid #536785;
}

.borderRight {
    border-right: 1px solid #536785;
}


.commonButton {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    color: #fff !important;
    font-family: 'NLRTM-Bold', 'Trebuchet MS', 'Arial', sans-serif;
    font-size: 12px;
    height: 50px;
    padding: 0 20px;
    text-transform: uppercase;
}

.menuDropDown {
    background-color: #001f4b !important;
    border: 1px solid #536785 !important;
    font-family: 'NLRTM-Bold', 'Trebuchet MS', 'Arial', sans-serif;
    font-size: 12px;
    font-weight: 1000;
    left: -1px;
    list-style-type: none !important;
    margin: 0 !important;
    padding: 0 !important;
    right: -1px;
    top: calc(100% - 1px);
    text-transform: uppercase;
    justify-content: center;
    position: relative !important;
    color: #fff !important;
}

.dropdownclass {
    align-items: center;
    /* border-left: 1px solid #536785;
    border-right: 1px solid #536785; */
    display: flex;
    justify-content: center;
    position: relative;
    color: #fff !important;
    font-family: 'NLRTM-Bold', 'Trebuchet MS', 'Arial', sans-serif;
    font-size: 12px;
    height: 50px;
    padding: 0 20px;
    text-transform: uppercase;
}

.dropdownAnchor {
    align-items: center;
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    background: #001f4b;
    border: 1px solid #536785;
    /* margin-left: 6px; */
    color: #fff;
    font-family: 'NLRTM-Bold', 'Trebuchet MS', 'Arial', sans-serif;
    font-weight: 1000;
    height: 50px;
    padding: 0 20px;
    text-transform: uppercase;
}