.custom-button:hover {
    background-color: rgb(0, 21, 52);
    color: #fff;
}

.custom-button {
    background-color: #f9d949;
    color: #001f4b;
}

.custom-login-button {
    align-items: center;
    background-color: #f9d949;
    color: #001f4b;
    cursor: pointer;
    flex-direction: row;
    font-family: 'NLRTM-Bold', 'Trebuchet MS', Arial, sans-serif;
    margin: 0;
    outline: none;
    padding: 12px 15px;
    text-transform: uppercase;
    transition: background-color .3s, color .3s;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
    border: none;
    border-radius: 8px;
}

.custom-login-button:hover {
    background-color: #001f4b;
    color: #fff;
}

.login-background {
    background-image: url(/public/login.jpg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    flex: 1 1;
}

.back-arrow {
    background-image: url(/public/back.png);
    background-position: -8px;
    background-repeat: no-repeat;
    background-size: 30px;
    display: inline-block;
    height: 30px;
    vertical-align: middle;
    width: 22px;
}

.buttonclass {
    align-items: 'center';
    background-color: '#f9d949';
    color: '#001f4b';
    cursor: 'pointer';
    display: 'flex';
    flex-direction: 'row';
    font-family: 'NLRTM-Bold', 'Trebuchet MS', Arial, sans-serif;
    margin: '0';
    outline: 'none';
    padding: '12px 15px';
    text-transform: 'uppercase';
    transition: 'background-color .3s; color .3s';
    user-select: 'none';
    white-space: 'nowrap';

}

/* Signin.css */

.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100vh;
}

.header {
    background-color: #222;
    color: #fff;
    display: flex;
    font-family: 'NLRTM-Bold', 'Trebuchet MS', Arial, sans-serif;
    font-size: 12px;
    line-height: 30px;
    width: 100%;
}

.header a {
    margin-right: auto;
    padding-left: 18.5px;
    text-decoration: none;
}

.header a span {
    background-position: -8px;
    background-repeat: no-repeat;
    background-size: 30px;
    height: 30px;
    vertical-align: middle;
    width: 22px;
}

.header a span img {
    vertical-align: middle;
}

.header a span:last-child {
    color: #fff;
    font-family: 'NLRTM-Bold', 'Trebuchet MS', Arial, sans-serif;
    font-size: 14px;
    font-weight: 1000;
}

.content {
    display: flex;
    flex: 1 1;
}

.image-container {
    width: 70%;
}

.login-container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 488px;
    padding: 25px;
}

.login-header {
    padding: 45px 40px;
    display: flex;
    align-items: center;
    background-color: #f2f1f1;
    padding: 24px;
}

.login-header img {
    margin-right: 8px;
}

.login-header h4 {
    color: #001f4b;
    display: flex;
    font-family: "NLRTM-Bold", sans-serif;
    font-size: 18px;
    font-weight: 1000;
}

.login-content {
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.login-title {
    align-items: center;
    color: #001f4b;
    display: flex;
    font-family: 'NLRTM-Bold', 'Trebuchet MS', Arial, sans-serif;
    font-size: 26px;
    font-weight: 1000;
    justify-content: space-between;
}

.error-message {
    align-items: center;
    color: red;
    display: flex;
    font-family: 'NLRTM-Bold', 'Trebuchet MS', Arial, sans-serif;
    font-size: 15px;
    font-weight: 200;
}

.submit-button {
    align-items: center;
    background-color: rgb(0, 21, 52);
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-family: 'NLRTM-Bold', 'Trebuchet MS', Arial, sans-serif;
    outline: none;
    padding: 20px 15px;
    text-transform: uppercase;
    transition: background-color .3s, color .3s;
    user-select: none;
    white-space: nowrap;
    margin: 25px 0 0 !important;
    font-weight: 1000;
}

.submit-button:hover {
    background-color: rgb(0, 21, 52);
    color: #fff;
}

.signup {
    color: #429ad1;
    font-family: 'NLRTM-Bold', 'Trebuchet MS', Arial, sans-serif;
    font-size: 14px;
    text-decoration: underline;
}

.login-paragraph {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
    font-family: 'NLRTM-Regular', 'Trebuchet MS', Arial, sans-serif;
}
